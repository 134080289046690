<template>
  <div>
    <!-- showcase -->
    <div class="showcase-xxs">
      <h4>Thank you for purchasing Talkii.</h4>
    </div>

    <div class="wrapper">
      <!-- section dashboard -->
      <section
        id="return-section"
        class="section jpadding jpadding-20 section-unfixed"
        style="background: #1193f5"
      >
        <div class="jcard jcard-main jcard-nohover">
          <div class="return-content">
            <img src="@/assets/vectors/talkii-illustration2.svg" alt="" />
            <!-- <div class="sub-title mb-4">
              <h3>Account</h3>
            </div>
            <p>As soon as your payment is completed. Your account will be activated.</p> -->
            <div v-if="!isLoggedIn" class="return-content-text">
              <p>
                <small>
                  Log in to your account to see the details of your
                  subscription.
                </small>
              </p>
              <div class="text-center">
                <router-link
                  class="jbtn jbtn-blue mt-3"
                  :to="{ name: 'Login' }"
                >
                  Login
                </router-link>
              </div>
            </div>
            <div v-else class="return-content-text">
              <div class="inner-jcard inner-jcard-70 mb-3">
                <div v-if="isLoadingId" class="text-center">
                  <i class="text-blue fad fa-spinner-third fa-spin fa-2x"></i>
                </div>
                <div v-else>
                  <p>
                    <small>SUBSCRIPTION ID</small><br />
                    <b>{{ validSubscriptionId }}</b>
                  </p>
                </div>
              </div>
              <p>
                <small>
                  We are currently processing your payment. But you can already
                  download and use the Talkii Apps now.
                </small>
              </p>
              <div class="text-center">
                <router-link
                  class="jbtn jbtn-blue jbtn-sm mt-3"
                  :to="{ name: 'Download' }"
                >
                  Download&nbsp;Center
                </router-link>
              </div>
              <p class="mt-4">
                <small>
                  Once the payment is completed, your account will be activated
                  automatically. You can see the details in your subscriptions
                  window.
                </small>
              </p>
              <div class="text-center">
                <router-link
                  class="jbtn jbtn-blue jbtn-sm mt-3"
                  :to="{ name: 'Dashboard' }"
                >
                  My&nbsp;Subscriptions
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <section class="separator separator-lux jpadding">
      <img src="@/assets/logos/made-in-lux.svg" alt="Made in Luxembourg" />
    </section>
  </div>
</template>

<script>
import firebase from "firebase/app";
//import C from "@/constants";
import PLANS from "@/plans";
import axios from "axios";
import toast from "@/assets/js/toast";

export default {
  name: "DashboardSingle",
  data() {
    return {
      subStatusList: PLANS.STATUS_INDEX,
      subTypeList: PLANS.TYPE_INDEX,
      firstname: "",
      lastname: "",
      homeTitle: "Bonjour ",
      isLoggedIn: false,
      isValidSubscription: false,
      subscriptionId: "INVALID",
      validSubscriptionId: "INVALID",

      isLoadingId: true,

      //userId: firebase.auth().currentUser.uid,
      subscriptionsList: [PLANS.DEFAULT_SUB],
    };
  },
  methods: {
    // startContrat(type) {
    //   this.$store.commit("startAddContrat");
    // },
    init() {
      //user must be logged in to see details
      if (firebase.auth().currentUser != null) {
        this.isLoggedIn = true;

        //check for query
        if (
          this.$route.query.subscription_id != null &&
          this.$route.query.subscription_id != ""
        ) {
          //set subscription id from query
          this.subscriptionId = this.$route.query.subscription_id;
          console.log(this.subscriptionId);
          //get subscription from paypal
          this.checkAndInitPayPalSubscription(this.subscriptionId);

          //ask for status

          // display id if subscription is valid
        } else {
          this.isLoadingId = false;
          console.log("No subscription id found.");
        }
      }
    },
    checkAndInitPayPalSubscription(subscriptionId) {
      //   let that = this;
      axios
        .post("/api/payment.php", {
          request: "initial_subscription_setup",
          subscriptionId: subscriptionId,
          userId: firebase.auth().currentUser.uid,
        })
        .then((response) => {
          //alert(response.data);
          console.log(response.data);
          //check if valid response, check status
          if (response.data === "OK") {
            this.validSubscriptionId = subscriptionId;
          } else {
            toast.error(response.data);
          }

          //update firebase user to unverified

          //create/udate platform user

          //create, if doesnt exist, subscription payment_process/unverified
          this.isLoadingId = false;
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.message);
          this.isLoadingId = false;
        });
    },

    openSubscription(index) {
      this.$store.commit("setSubscription", this.subscriptionsList[index]);
      this.$router.push({
        name: "Subscription",
        params: { id: this.subscriptionsList[index].id },
      });
    },
    getFormatDate(date) {
      console.log(date);
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped></style>
